import './App.css';

function App() {
  return (
    <div className="App">
      <header className='header'>
        <h1 className="title">
          Designing for the Human-In-The-Loop
        </h1>
      </header>

    </div>
  );
}

export default App;
